//------------------------------------------------------------------
//	BOOTSTRAP SETUP
//------------------------------------------------------------------
@import 'common/variables';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/badge';

//------------------------------------------------------------------
//	CORE ADMIN STYLES
//------------------------------------------------------------------
@import 'node_modules/mlmi-library/components/admin/v1/admin';
@import 'node_modules/mlmi-library/components/form/v1/form';
@import 'node_modules/mlmi-library/components/select/v1/select';

.wp-admin {
  .acf-field[data-name='credit_image'],
  .acf-field[data-name='grid_repeater_credit_image'] {
    > .acf-input {
      img[data-name='image'] {
        width: 100px !important;
        height: 100px !important;
        border-radius: 50%;
      }
    }
  }
}

.acf-field[data-name='grid_layout'] {

  img {
    width: 100px;
  }

  select {
    display: none;
  }

  .select {
    max-width: 150px;

    &__selected {
      background: #fff;
      top: 0;
      left: 0;

      &::after {
        display: none;
      }
    }

    &__options {
      width: 300px;
      background: $white;
      border: 1px solid #eee;
      border-radius: 3px;
      box-shadow: 3px 3px 5px rgba($dark, .05);

      img {
        width: 80%;
      }

      ul {
        width: 100%;
        display: flex;
        flex-flow: wrap;

        li {
          flex: 33% 0 0;
          padding: 10px 0;
          text-align: center;
          margin: 0;

          &:hover,
          &.selected {
            background-color: #eee;
          }

          img {
            line-height: 60px;
            vertical-align: middle;
            pointer-events: none;
          }
        }
      }
    }
  }
}
